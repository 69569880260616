
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "EducationBlock",
  props: {
    studentExamAndEducations: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const updateSlideHeight = inject("updateSlideHeight", () => null, false);

    const onChangeTab = () => {
      updateSlideHeight();
    };

    return {
      onChangeTab,
    };
  },
});
